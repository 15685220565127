import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgLogoHere from "../../assets/images/home/logo-here.png"

const PartnersPage = () => (
  <Layout>
    <SEO title="EMM Team" />
    <main>
      <section id="about-emm" style={{position: "relative", marginTop: "200px"}}>
          <div className="large-container pv6 mv6 ph2">
                <h2 className="uk-text-center mb5">
                    Partners
                </h2>
                <div className="grid-col-3">
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                    <div className="pa3 pa5-ns">
                        <img src={imgLogoHere} className="uk-align-center" alt=""/>
                    </div>
                </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default PartnersPage
